@import "variables.scss";
@import "animations.scss";
@import "form.scss";
@import "icons.scss";
@import "navbar.scss";
@import "views.scss";

html {
  margin-left: calc(100vw - 100%);
}

body {
  color: $theme-color-text;
  margin: 0;
  padding: $padding-max 0;

  @media (max-width: $mobile-break-point) {
    padding: 0;
  }
}
  
.desktop {
  display: initial;
  @media (max-width: $mobile-break-point) {
    display: none;
  }
}
.mobile {
  display: none;
  @media (max-width: $mobile-break-point) {
    display: initial;
  }
}

.container {
  margin-top: $padding-mid;
  max-width: $content-max-width;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $theme-title-font;
  margin: $padding-min 0;
}

h1 {
  margin: $padding-max 0;
  text-align: center;
}
h2 {
  margin: $padding-max 0 $padding-min 0;
  text-align: center;
}

a,
button {
  outline: none !important;
}

a,
a:visited,
.link-button {
  color: $theme-color-light;
  cursor: pointer;
  transition: color 300ms ease, text-shadow 1000ms ease;
  
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    color: $theme-color;
    text-shadow: -1px 1px 1px $theme-color-lightest, 0px 0px 1px $theme-color-light;
    text-decoration: none;
    svg {
      fill: $theme-color;
    }
  }
  svg {
    fill: $theme-color-light;
    transition: fill 300ms ease;
  }
}

.popover-body div {
  text-align: center;
}

.secondary-image {
  float: left;
  margin: 0;
  width: 27%;
  img {
    max-width: 100%;
    max-height: 100%;
    &:not(:last-of-type) {
      margin-bottom: 8.6%;
    }
  }
}
.main-image {
  float: left;
  margin: 0 2.5% 0 0;
  width: 70.5%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.modal {
  .modal-title {
    font-weight: bold;
    margin: 0 auto;
  }
}
.modal.waiting .modal-dialog {
  display: table;
  position: relative;
  margin: 0 auto;
  top: calc(50% - 24px);
  .modal-content {
    background-color: transparent;
    border: none;
    .icon {
      animation-duration: 1s;
      animation-name: flip;
      animation-iteration-count: infinite;
    }
  }
}

address {
  text-align: center;
  margin-bottom: $padding-max;
}

.cake-icon {
  fill: $theme-color-light;
}

.version {
  color: $theme-color-feint;
  font-size: 0.6rem;
  text-align: center;
}

.price-list {
  margin: 0 auto;
  width: 60%;
  table tr td {
    width: 50%;
  }
}

@media print {
  .price-list h2 {
    margin-top: 0;
    page-break-before: always;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}