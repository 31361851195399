.main {
  .main-image {
    img {
      border-bottom-left-radius: .25rem;
      border-top-left-radius: .25rem;
    }
    @media (max-width: $mobile-break-point) {
      float: none;
      margin: 0;
      width: 100%;
      img {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: .0;
      }
    }
  }
  .secondary-image {
    img:first-of-type {
      border-top-right-radius: .25rem;
    }
    img:last-of-type {
      border-bottom-right-radius: .25rem;
    }
    @media (max-width: $mobile-break-point) {
      float: left;
      margin: 0;
      width: 100%;
      img {
        
        width: 47%;
        &:first-of-type {
          border-bottom-left-radius: .25rem;
          border-top-right-radius: 0;
        }
        &:not(:last-of-type) {
          margin-right: 6%;
          margin-top: 6%;
          margin-bottom: 0;
        }
        &:last-of-type {
          margin-top: 6%;
          margin-bottom: 0;
        }
      }
    }
  }

  .alert-primary {
    background-color: $theme-color-lightest;
    border-color: $theme-color-lightest;
    color: $theme-color-text;
    padding-left: 110px;
    &.action {
      cursor: pointer;
    }
    h5, p {
      text-align: left;
    }
    h5 {
      font-weight: bold;
    }
    .icon {
      fill: $theme-color-text;
      height: 120px;
      left: 10px;
      position: absolute;
      top: 0;
      width: 80px;
    }
    .icon-suitcase {
      fill: $theme-color-text;
      top: 10px;
      transform: rotate(-25deg);
    }
    .icon-map {
      margin: 0;
      height: 18px;
      width: 18px;
    }
  }
  p {
    text-align: center;
  }
  .instagram-feed {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: $padding-max 0 $padding-mid 0;
    .image {
      float: left;
      margin-bottom: 1%;
      overflow: hidden;
      padding-top: 19%;
      position: relative;
      width: 19%;

      @media (max-width: $mobile-break-point) {
        padding-top: 47%;
        margin-bottom: 6%;
        width: 47%;
      }
      img {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
}

.products {
  .card-columns {
    columns: 2;
    @media (max-width: $mobile-break-point) {
      columns: 1;
      max-width: 576px;
      margin: 0 auto;
    }
  }
  .card {
    border: none;

    // &:hover,
    &.expanded {
      .card-body .ingredient .ingredient-list {
        max-height: 350px;
        padding: 10px 20px;
      }
      .image-crop .card-img-top {
        margin: -10% 0 -30% 0;
      }
    }

    .card-body {
      padding: 0;
      text-align: center;
      h2 {
        margin: $padding-min 0;
        padding: 0 20px;
      }
      p {
        padding: 0 20px;
        text-align: center;
        &:last-of-type {
          margin-bottom: 2rem;
        }
      }
      .ingredient {
        padding: 0;
        .ingredient-list {
          font-size: 0.8rem;
          max-height: 0;
          overflow: hidden;
          padding: 0 20px;
          text-align: left;
          background: rgba(250,250,250,1);
          transition: all 1000ms ease;
        }
      }
    }
    .prices {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: $padding-min 0;
      text-align: center;

      .price {
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
        text-align: center;
        button {
          display: flex;
          height: 50px;
          padding: 0 5px;
          width: 100px;
          
          &.adding {
            animation: puff-out-center 0.6s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
          }

          &:hover .count,
          &:focus .count{
            background-color: $theme-color-light;
            color: #fff;
          }

          div {
            align-self: center;
            display: flex;
            width: 50px;
            &:not(.icon-multiple) svg {
              margin-left: auto;
            }
          }

          .text {
            align-self: center;
            display: flex;
            width: 40px;
          }
        }
        .text {
          width: 100px;
          text-align: center;
        }
      }        
      p {
        color: $theme-color-disabled;
        font-size: 0.8rem;
        margin: 5px 0 2px 0;
        text-align: center;
      }
    }
    .image-crop {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: block;
      overflow: hidden;
      padding: 0;
      
      .card-img-top {
        margin: 0;
        transition: margin ease 1000ms;
      }
    }
  }
}

.background {
  .main-image {
    img {
      border-bottom-right-radius: .25rem;
      border-top-right-radius: .25rem;
    }
    @media (max-width: $mobile-break-point) {
      float: none;
      margin: 0;
      width: 100%;
      img {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0;
      }
    }
  }
  .secondary-image {
    img:first-of-type {
      border-top-left-radius: .25rem;
    }
    img:last-of-type {
      border-bottom-left-radius: .25rem;
    }
    @media (max-width: $mobile-break-point) {
      float: left;
      margin: 0;
      width: 100%;
      img {
        width: 47%;
        &:last-of-type {
          border-top-right-radius: .25rem;
          border-bottom-left-radius: 0;
          margin-bottom: 6%;
          margin-top: 0;
        }
        &:not(:last-of-type) {
          margin-bottom: 6%;
          margin-top: 0;
          margin-right: 6%;
        }
      }
    }
  }
  p {
    text-align: center;
  }
}

.order {
  .popover {
    border-color: $theme-color-feint;
    z-index: 999;
    .popover-body {
      background-color: $theme-color-disabled;
      color: #fff;
    }
    &.bs-popover-top .arrow {
      &:after {
        border-top-color: $theme-color-disabled;
      }
      &:before {
        border-top-color: $theme-color-feint;
      }
    }
    &.bs-popover-bottom .arrow {
      &:after {
        border-bottom-color: $theme-color-disabled;
      }
      &:before {
        border-bottom-color: $theme-color-feint;
      }
    }
  }
  .sub-text {
    font-size: 0.8rem;
    label {
      display: inline;
    }
  }
  .icon-size-X,
  .icon-size-S,
  .icon-size-M,
  .icon-size-L {
    display: inline-block;
    text-align: center;
    width: 50px;
  }
  .product-list {
    margin: 0 0 $padding-mid 0;
    padding: 0;
    .row {
      overflow: hidden;
    }
    .col, .col-1, .col-2, .col-3, .col-4 {
      padding: 0;
    }
    .image {
      cursor: pointer;
      &:hover,
      &:focus {
        img {
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $theme-color-lightest;
        }
        .icon-info {
          fill: $theme-color;
        }
      }
      img {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        margin: 0 0 0 15px;
        max-width: 62px;
      }
      .icon-info {    
        fill: $theme-color-light;
        left: 62px;
        position: absolute;
        top: 0;
        transition: fill ease 300ms;
      }
    }
    .price {
      text-align: right;
      &.total {
        font-weight: bold;
        margin-right: 43px;
      }
    }
    .close {
      position: relative;
      top: -2px;
      margin: 0 10px 0 0;
    }
  }
}