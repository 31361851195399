@mixin positions($b) {
  @for $i from 1 through 5 {
    &:nth-child(#{($b - 1) * 5 + $i - 1}) {
      @if $i % 4 == 0 {
        margin-left: $i * -4px + $b * 2;
        margin-top: $i * 4px - $b * 2;
      }
      @if $i % 4 == 1 {
        margin-left: $i * -4px + $b * 2;
        margin-top: $i * 2px - $b * 2;
      }
      @if $i % 4 == 2 {
        margin-left: $i * -1px + $b * 2;
        margin-top: $i * 5px - $b * 2;
      }
      @if $i % 4 == 3 {
        margin-left: $i * -5px + $b;
        margin-top: $i * 1px - $b * 2;
      }
    }
  }
}

.icon-size-X {
  display: inline-block;
  height: 30px;
  margin-top: 10px;
  svg {
    height: 15px;
    margin: 3px 15px;
    width: 15px;
  }
}
.icon-size-S {
  display: inline-block;
  height: 32px;
  margin-top: 8px;
  svg {
    height: 20px;
    margin: 2px 10px;
    width: 20px;
  }
}
.icon-size-M {
  display: inline-block;
  height: 35px;
  margin-top: 5px;
  svg {
    height: 30px;
    margin: 0 10px;
    width: 30px;
  }
}
.icon-size-L {
  display: inline-block;
  height: 40px;
  svg {
    height: 40px;
    margin: 0 5px;
    width: 40px;
  }
}

.icon-nr {
  border-radius: 50%;
  border: 1px solid $theme-color-light;
  display: inline-block;
  height: 32px;
  margin: 5px;
  padding: 2px;
  text-align: center;
  transition: background-color 300ms ease;
  width: 32px;
  &:hover {
    border-color: $theme-color;
  }
}

.icon-multiple {
  display: inline-block;
  height: 35px;
  position: relative;
  width: 40px;
  
  svg {
    left: 20px;
    max-width: 20px;
    max-height: 20px;
    position: absolute;
    top: 2px;
    @include positions(1);
    @include positions(2);
    @include positions(3);
    @include positions(4);
    @include positions(5);
  }

  $count-badge-size: 22px;
  $count-badge-font-size: 12px;
  .count {
    background-color: #fff;
    border: 1px solid $theme-color-light;
    border-radius: 100%;
    box-shadow: 0px 2px 6px rgba(97, 97, 97, 0.16), 0px 2px 6px rgba(97, 97, 97, 0.23);
    display: block;
    font-size: $count-badge-font-size;
    font-weight: bold;
    height: $count-badge-size;
    padding: 1px 0;
    margin: 9px 0 0 12px;
    position: absolute;
    text-align: center;
    width: $count-badge-size;
  }
}

svg.icon-map {
  margin: 0 0 0 5px;
  height: 25px;
  width: 25px;
}

.icon-info {
  height: 15px;
  width: 15px;
}

.divider {
  padding: $padding-min $padding-mid $padding-mid $padding-mid;
  text-align: center;

  @media (max-width: $mobile-break-point) {
    padding: $padding-min $padding-min $padding-max $padding-min;
    &.mobile {
      display: block;
      padding-bottom: 0;
    }
  }

  svg.icon {
    fill: $theme-color-feint;
    height: 25px;
    width: 50px;
    margin: 0 $padding-min 4px;
    &.icon-cake {
      height: 35px;
      width: 35px;
      margin: 0;
    }
    &.icon-dividerLeft, &.icon-dividerRight {
      transform: rotateX(180deg);
    }
  }
}

.icon-magnifier {
  position: absolute;
  height: 20px;
  width: 20px;
  top: $padding-min;
  right: $padding-min;
}

.flying {
  color: $theme-color-light;
  fill: $theme-color-light;
  position: absolute;
  z-index: 100;
  .count {
    border-color: $theme-color-light;
    margin: 9px -9px;
  }
}

.icon-add {
  height: 20px;
  margin-right: $padding-min;
  position: relative;
  top: 4px;
  width: 20px;
}