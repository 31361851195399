$theme-color: rgba(255, 105, 180, 1);
$theme-color-lightest: rgba(255, 105, 180, 0.1);
$theme-color-light: rgba(255, 105, 180, 0.66);
$theme-color-text: #333;
$theme-color-disabled: gray;
$theme-color-feint: #dddddd;
$theme-title-font: "Alegreya", serif;
$content-max-width: 720px;
$padding-min: 10px;
$padding-mid: 20px;
$padding-max: 40px;

$mobile-break-point: 767.98px;