form {
  padding-bottom: $padding-mid;
  .btn-primary {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.btn:disabled,
.btn:disabled:hover {
  background-color: $theme-color-feint;
  border-color: $theme-color-disabled;
  color: #fff;
}
.btn-primary,
.btn-default {
  background-color: $theme-color;
  border: 1px solid $theme-color;
  color: #fff;
  transition: background-color 300ms ease, border 300ms ease;
  &:hover {
    background-color: $theme-color-light;
    border: 1px solid $theme-color-light;
    color: #fff;
  }
  &:focus,
  &:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $theme-color-light;
  }
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus:active {
    background-color: $theme-color-light;
    border: 1px solid $theme-color;
    color: #fff;
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $theme-color-lightest;
  }
}
.btn-outline-secondary {
  border: 1px solid $theme-color-lightest;
  color: $theme-color;
  fill: $theme-color;
  transition: background-color 300ms ease, border 300ms ease, fill 300ms ease;
  &:hover {
    background-color: $theme-color-light;
    border: 1px solid $theme-color-lightest;
    color: #fff;
  }
  &:focus,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):focus:active,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $theme-color-light;
    border: 1px solid $theme-color-light;
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $theme-color-lightest;
    color: #fff;
  }
}
button.link-button {
  border: none;
  background: none;
  &.info-icon svg {
    margin-bottom: -6px;
  }
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $theme-color;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $theme-color-lightest;
}

.form-group {
  padding-bottom: 0;
}

.form-control:focus,
.form-control:hover:not(:disabled) {
  color: $theme-color-text;
  background-color: #fff;
  border-color: $theme-color-lightest;
  outline: 0;
  box-shadow: 0 0 0 0.2rem $theme-color-lightest;
}

option[disabled] {
  color: $theme-color-disabled;
  font-style: italic;
}

*::placeholder {
  font-style: italic;
}


.location-select {
  &.one-selected {
    .selected {
      border-color: $theme-color-light;
      color: $theme-color-text;
    }
  }
  .form-control {
    border-color: $theme-color-lightest;
    cursor: pointer;
    height: auto;
  }
}