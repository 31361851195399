$navbar-action-width-mobile: 76px;

.sticky-top {
  top: -1px;
}

.navbar-toggler {
  border: none;
  background: transparent !important;
  border-radius: 50%;
  height: 46px;
  width: 46px;

  &:focus {
    outline: none;
    background: transparent !important;
  }
  &:not(.collapsed) {
    .icon-bar:nth-child(1) {
      transform: rotate(45deg) translate(5px, 4px);
      transition: ease all 0.2s;
    }
    .icon-bar:nth-child(2) {
      opacity: 0;
      transition: ease all 0.2s;
    }
    .icon-bar:nth-child(3) {
      transform: rotate(-45deg) translate(4px, -4px);
      transition: ease all 0.2s;
    }
  }
  .icon-bar {
    animation-duration: 1s;
    animation-name: grow;

    background-color: $theme-color;
    border-radius: 1px;
    display: block;
    height: 2px;
    transform: rotate(0deg) translate(0px, 0px);
    transition: ease all 0.2s;
    width: 22px;

    & + .icon-bar {
      margin-top: 4px;
    }
  }
}



.navbar.container {
  @media (max-width: $mobile-break-point) {
    margin-top: 0;
  }
}

.navbar-light {
  background-color: rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 10px 10px 5px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0px 10px 10px 5px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 10px 10px 5px rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  padding-top: 15px;

  @media (max-width: $mobile-break-point) {
    border-bottom: 2px solid $theme-color-lightest;
  }
  .navbar-toggler {
    border-color: transparent;

    @media (max-width: $mobile-break-point) {
      margin-right: $navbar-action-width-mobile - 46px;
    }
  }
  .navbar-brand {
    margin-right: 0;
    .logo-image {
      display: none;
      height: $padding-max;
      width: $padding-max * 1.475;

      @media (max-width: $mobile-break-point) {
        display: block;
      }
    }
  }
  .navbar-nav {
    .nav-link {
      color: $theme-color-light;
      &:hover,
      &:active,
      &:focus {
        color: $theme-color;
      }
    }
    .active > .nav-link,
    .nav-link.active,
    .nav-link.show,
    .show > .nav-link {
      color: $theme-color;
      &:not(:hover) {
        text-shadow: none;
      }
    }
  }
  &>.order.mobile {
    @media (max-width: $mobile-break-point) {
      margin-top: -5px;
      width: $navbar-action-width-mobile;
    }
  }
  .order {
    &.mobile {
      @media (max-width: $mobile-break-point) {
        .btn {
          position: relative;
        }
        .badge {
          right: -12px;
        }
      }
    }
    &.desktop {
      position: absolute;
      right: 15px;
    }
    .btn {
      font-size: 0.8rem;
      padding: 2px $padding-min;
      text-transform: uppercase;
      
      @media (min-width: $mobile-break-point) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      $badge-size: 24px;
      $badge-font-size: 12px;
      .badge {
        background-color: #fff;
        border-radius: 100%;
        box-shadow: 0px 2px 6px rgba(97, 97, 97, 0.16), 0px 2px 6px rgba(97, 97, 97, 0.23);
        color: $theme-color-text;
        font-size: $badge-font-size;
        height: $badge-size;
        margin-left: -4px;
        padding: (($badge-size - $badge-font-size - 2) / 2) + 1 0;
        position: absolute;
        text-align: center;
        top: $badge-size * -0.5;
        width: $badge-size;
        z-index: 10000;
        &.pulse {
          animation: puff-in-center 0.6s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
        }
        .icon-circle {
          fill: $theme-color;
          height: $badge-size + 1;
          left: -1px;
          position: absolute;
          top: 0;
          width: $badge-size + 1;
        }
      }
    }
  }

  .language-switch {
    left: 15px;
    position: absolute;
    top: 25px;
    z-index: 9999;
    
    @media (max-width: $mobile-break-point) {
      display: flex;
      height: $padding-max;
      margin: $padding-min auto;
      position: initial;
      width: 90%;
      .btn {
        line-height: $padding-max - 5px;
      }
    }
    .btn-secondary {
      background-color: #fff;
      border-color: $theme-color-lightest;
      color: $theme-color-light;
      font-size: 0.8rem;
      padding: 1px 8px;
      transition: all ease 500ms;
      
      @media (min-width: $mobile-break-point) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.active,
      &.active:focus  {
        background-color: $theme-color-light;
        border-color: $theme-color-lightest;
        color: #fff;
      }
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):focus,
      &.active:not(:disabled):not(.disabled):active,
      &.active:not(:disabled):not(.disabled):focus {
        background-color: #fff;
        border-color: $theme-color-lightest;
        box-shadow: none;
        color: $theme-color-light;
      }
    }
  }
}

a:hover .logo-image,
.logo-image {
  display: block;
  fill: #111;
  margin: $padding-min auto;
  opacity: 0.75;
  width: 100px;

  &:hover {
    fill: #111;
  }
  
  @media (max-width: $mobile-break-point) {
    display: none;
  }
}